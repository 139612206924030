html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #f5f7fb;
  font-family: Roboto, sans-serif;
}

body {
  background: #18181A;
}